import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-velocity';
const WindVelocityLayer = ({ dataUrl }) => {
  const map = useMap();

  useEffect(() => {
    let velocityLayer;

    const fetchWindData = async () => {
      try {
        const response = await fetch(dataUrl);
        const windData = await response.json();

        velocityLayer = L.velocityLayer({
          displayValues: true,
          displayOptions: {
            velocityType: 'Global Wind',
            position: 'bottomleft',
            emptyString: 'No velocity data'
          },
          data: windData,
          maxVelocity: 10,
          colorScale: ['#0000FF', '#00FF00', '#FFFF00', '#FF0000']
        });

        velocityLayer.addTo(map);
      } catch (error) {
        console.error('Error fetching wind data:', error);
      }
    };

    fetchWindData();

    // Cleanup function to remove the layer when the component is unmounted
    return () => {
      if (velocityLayer) {
        map.removeLayer(velocityLayer);
      }
    };
  }, [map, dataUrl]);

  return null;
};
export default WindVelocityLayer;