import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

// Optional: Custom icon for fire markers
const fireIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/2318/2318912.png', // Example fire icon URL
  iconSize: [25, 25],
  iconAnchor: [12, 12],
});

const ActiveFiresLayer = ({ dataUrl }) => {
  const map = useMap();
  const [fireLayer, setFireLayer] = useState(null);

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component

    const fetchFireData = async () => {
      try {
        const response = await axios.get(dataUrl);
        const geojsonData = response.data;

        // Create a GeoJSON layer
        const layer = L.geoJSON(geojsonData, {
          pointToLayer: (feature, latlng) => {
            return L.marker(latlng, { icon: fireIcon });
          },
          onEachFeature: (feature, layer) => {
            // Popup content for each fire point
            const props = feature.properties;
            const popupContent = `
              <strong>Confidence:</strong> ${props.confidence}<br/>
              <strong>Brightness:</strong> ${props.bright}<br/>
              <strong>Scan:</strong> ${props.scan}<br/>
              <strong>Track:</strong> ${props.track}<br/>
              <strong>Date:</strong> ${props.acq_date} ${props.acq_time}
            `;
            layer.bindPopup(popupContent);
          },
        });

        // Optionally, style the layer (e.g., set color based on brightness)
        // You can customize the styling here

        if (isMounted) {
          setFireLayer(layer);
          layer.addTo(map);
        }
      } catch (error) {
        console.error('Error fetching active fires data:', error);
      }
    };

    fetchFireData();

    // Cleanup function to remove the layer when the component is unmounted
    return () => {
      isMounted = false;
      if (fireLayer) {
        map.removeLayer(fireLayer);
      }
    };
  }, [map, dataUrl]);

  return null; // This component does not render anything directly
};

export default ActiveFiresLayer;