import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap, LayersControl, WMSTileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapWithPanel.css';
import WindVelocityLayer from './WindVelocityLayer';
import ActiveFiresLayer from './ActiveFiresLayer';

export const MapWithPanel = () => {
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const [panelContent, setPanelContent] = useState(null);
	const [selectedLayer, setSelectedLayer] = useState("none");


  const togglePanel = (contentType) => {
	setPanelContent(contentType);
    	setIsPanelOpen(!isPanelOpen);
  };
const currDay = new Date().getDay()-2;
const currMonth = new Date().getMonth() +1;
const currYear = new Date().getFullYear();
const currDate = currYear + '/' + currMonth + '/' + currDay;
//console.log(currDate.getDay());
//console.log(currDate.getMonth());
console.log(currDate);
//const weatherLayerUrl = "https://gibs.earthdata.nasa.gov/wmts/epsg3857/best/"
//const windLayerUrl = "http://tile.openweathermap.org/map/weather/WND/{z}/{x}/{y}?appid=886d68fe73eef622c486b44927b1cf8e";
const windLayerUrl = "https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const windDataUrl = "https://onaci.github.io/leaflet-velocity/wind-global.json";
const activeFiresDataUrl = "https://gibs.earthdata.nasa.gov/wmts/epsg3857/best/VIIRS_NOAA21_Thermal_Anomalies_375m_All/default/2024-04-09/GoogleMapsCompatible_Level6/{z}/{x}/{y}.png"
const popDataUrl = "https://gibs.earthdata.nasa.gov/wmts/epsg3857/best/GPW_Population_Density_2020/default/2024-04-09/GoogleMapsCompatible_Level6/{z}/{x}/{y}.png"
const popDataUrl2 = "https://sedac.ciesin.columbia.edu/geoserver/wms"
// ?service=WMS&request=GetMap&format=image%2Fpng&width=256&height=256&layer=gpw-v4:gpw-v4-population-density-adjusted-to-2015-unwpp-country-totals-rev11_2020&BBOX=-180,-90,180,90"
const wmsLayerUrl = "https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/4845fb54830d33c5a55e86f3111b9b47/fires_viirs_noaa21_7/?REQUEST=GetMap&WIDTH=1024&HEIGHT=512&BBOX=-180,-90,180,90&symbols=circle-uf&colors=250+0+0&size=6&TIME=2025-01-01/2025-01-02"
const tempLayerUrl = "https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const precpLayerUrl = "https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const cloudLayerUrl = "https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const pressLayerUrl = "https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const baseLayerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
 const handleLayerChange = (event) => {
    setSelectedLayer(event.target.value);
  };

 const renderPanelContent = () => {
    switch (panelContent) {
      case 'login':
        return (
          <div className="panel-content">
            <h3>Login</h3><br/>
            <input type="text" placeholder="Username" /><br/>
            <input type="password" placeholder="Password" /><br/>
            <button>Submit</button><br/>
          </div>
        );
	case 'menu':
        return (
          <div className="panel-content">
            <h3>Login</h3>
            <input type="text" placeholder="Username" /><br/>
            <input type="password" placeholder="Password" /><br/>
            <button>Submit</button><br/>
            <h3>Goto</h3><br/>
            <input type="text" placeholder="Lattitude" /><br/>
            <input type="text" placeholder="Longitude" /><br/>
            <button>Go</button><br/>
            <h3>Layers</h3><br/>
		<label>
              <input
                type="radio"
                name="layer"
                value="none"
                onChange={handleLayerChange}
                checked={selectedLayer === "none"}
              />
              None
            </label><br/>
		<label>
              <input type="radio" name="layer" value="ActiveFires" onChange={handleLayerChange} checked={selectedLayer === "ActiveFires"}/>
              Active Fires
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PredictedRiskQuotient" onChange={handleLayerChange} checked={selectedLayer === "PredictedRiskQuotient"}/>
              Predicted Risk Quotient
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Weather" onChange={handleLayerChange} checked={selectedLayer === "Weather"}/>
              Weather
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Wind"onChange={handleLayerChange} checked={selectedLayer === "Wind"} />
              Wind
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Moisture" onChange={handleLayerChange} checked={selectedLayer === "Moisture"}/>
              Moisture
            </label><br/>
            <label>
<input type="radio" name="layer" value="PopulationDensity" onChange={handleLayerChange} checked={selectedLayer === "PopulationDensity"}/>
              Population Density
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Recommendations" onChange={handleLayerChange} checked={selectedLayer === "Recommendations"}/>
              Recommendations
            </label><br/>
	</div>
        );
	case 'gps':
        return (
          <div className="panel-content">
            <h3>Goto</h3><br/>
            <input type="text" placeholder="Lattitude" /><br/>
            <input type="text" placeholder="Longitude" /><br/>
            <button>Go</button><br/>
          </div>
        );
      case 'layers':
        return (
          <div className="panel-content">
            <h3>Layers</h3><br/>
            <label>
              <input type="radio" name="layer" value="ActiveFires" />
              Active Fires
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PredictedRiskQuotient " />
              Predicted Risk Quotient
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Weather" onChange={handleLayerChange} checked={selectedLayer === "Weather"}/>
              Weather
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Wind" />
              Wind
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Moisture" />
              Moisture
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PopulationDensity" />
              Population Density
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Recommendations" />
              Recommendations
            </label><br/>
          </div>
        );
      default:
        return <div>Select an option</div>;
    }
  };

  return (
<div>
	  <div className="navBar">
	  <button onClick={() => togglePanel('menu')} class="menu">
<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 -2 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
</svg>

	  </button>
        <h3>&nbsp;TriNater</h3>
	  <button class="loginButton" onClick={() => togglePanel('login')}>Login</button>
      </div>
    <div className="app-container">


        <button onClick={() => togglePanel('login')} className="pullTab">
	  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2"/>
</svg>
        </button>



	  <div className={`side-panel ${isPanelOpen ? 'open' : 'closed'}`}>
	  <div id="panel-content">
<button onClick={() => setIsPanelOpen(false)} className="loginButton">
          X
        </button>
        {isPanelOpen && renderPanelContent()}


	</div>
	</div>



	  <div className="map-container">

	  <div className="floating-buttons">
          <button id='lyr' onClick={() => togglePanel('layers')} className="layers-button">
	<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5.005 10.19a1 1 0 0 1 1 1v.233l5.998 3.464L18 11.423v-.232a1 1 0 1 1 2 0V12a1 1 0 0 1-.5.866l-6.997 4.042a1 1 0 0 1-1 0l-6.998-4.042a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1ZM5 15.15a1 1 0 0 1 1 1v.232l5.997 3.464 5.998-3.464v-.232a1 1 0 1 1 2 0v.81a1 1 0 0 1-.5.865l-6.998 4.042a1 1 0 0 1-1 0L4.5 17.824a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
  <path d="M12.503 2.134a1 1 0 0 0-1 0L4.501 6.17A1 1 0 0 0 4.5 7.902l7.002 4.047a1 1 0 0 0 1 0l6.998-4.04a1 1 0 0 0 0-1.732l-6.997-4.042Z"/>
</svg>

	  </button>
          <button onClick={() => togglePanel('gps')} className="maps-button">
<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
</svg>

	  </button>
        </div>


	  <MapContainer center={[45.5152, -122.6784]} zoom={6} style={{ height: '100%', width: '100%' }}>

<LayersControl position="topright" button='lyr'>
        <LayersControl.BaseLayer name="Base Map" checked>
          <TileLayer
            url={baseLayerUrl}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay name="Weather" checked={selectedLayer === "Weather"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Weather" && (
            <>
              <TileLayer
                 url={cloudLayerUrl}
                 attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
               />
            </>
            )}
        </LayersControl.Overlay>


        <LayersControl.Overlay name="Wind" checked={selectedLayer === "Wind"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Wind" && (
            <>
             
          <WindVelocityLayer dataUrl={windDataUrl} />

            </>
            )}
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Moisture" checked={selectedLayer === "Moisture"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Moisture" && (
            <>
             <TileLayer
                 url={precpLayerUrl}
                 attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
               />
            </>
            )}
        </LayersControl.Overlay>

        <LayersControl.Overlay name="ActiveFires" checked={selectedLayer === "ActiveFires"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "ActiveFires" && (
            <>
              <WMSTileLayer
                 url={wmsLayerUrl}
                 params={{
                  format:"image/png",
                  transparent: true,
                }}
                 attribution='&copy; <a>NASA</a> '
               />
            </>
            )}
        </LayersControl.Overlay>

        <LayersControl.Overlay name="PopulationDensity" checked={selectedLayer === "PopulationDensity"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "PopulationDensity" && (
            <>
               <WMSTileLayer
            url="https://sedac.ciesin.columbia.edu/geoserver/wms"
            layers="gpw-v3:gpw-v3-population-density-future-estimates_2015"
            format="image/png"
            transparent={true}
            opacity={.3}
            attribution="SEDAC"
          />
            </>
            )}
        </LayersControl.Overlay>

        <LayersControl.Overlay name="PredictedRiskQuotient" checked={selectedLayer === "PredictedRiskQuotient"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "PredictedRiskQuotient" && (
            <>
              <WMSTileLayer
                 url={wmsLayerUrl}
                 params={{
                  format:"image/png",
                  transparent: true,
                }}
                 attribution='&copy; <a></a> '
               />
            </>
            )}
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Recommendations" checked={selectedLayer === "Recommendations"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Recommendations" && (
            <>
              <TileLayer
                 url={popDataUrl}
                 attribution='&copy; <a >NASA</a> '
               />
            </>
            )}
        </LayersControl.Overlay>


      </LayersControl>




        </MapContainer>




      </div>

	  </div>
	  </div>
  );
};
